/* eslint-disable jsx-a11y/accessible-emoji */

import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Roadmap extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'gooey roadmap',
      paragraph: 'Building premium tools on SEI for investors and projects. \n\nPHASE 1'
    };

    const sectionHeader2 = {
      title: '',
      paragraph: 'PHASE 2'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem>
                <div>✅ Discord & Social Launch</div>
              </TimelineItem>
              <TimelineItem>
                <div>🛠️ Gooey Sweeper - <b>NFT floor sweeper</b></div>
              </TimelineItem>
              <TimelineItem>
                <div>egg hunt begins...</div>
              </TimelineItem>
              <TimelineItem>
                <div>Gooey Spins - <b>Ticket-based rewards distribution system</b></div>
              </TimelineItem>
              <TimelineItem>
                <div>Gooey Escrow - <b>Safe trading between two parties</b></div>
              </TimelineItem>
              <TimelineItem>
                <div>Gooey Flips - <b>Win rewards on a 50/50 flip</b></div>
              </TimelineItem>
              <TimelineItem>
                <div>Gooey Distributor - <b>Airdrops made easy</b></div>
              </TimelineItem>
              {/* <TimelineItem>
                <div>Gooey Bidder - <b>Automated NFT bidding system</b></div>
              </TimelineItem> */}
              {/* <TimelineItem>
                <div>Gooey Save - <b>Easily download your favorite NFTs in the highest resolution</b></div>
              </TimelineItem> */}
              <TimelineItem>
                <div>Gooey Snapshot - <b>Snapshots how projects want them</b></div>
              </TimelineItem>
              <TimelineItem>
                <div>Eggs Hatch</div>
              </TimelineItem><br></br><br></br><br></br>
              <SectionHeader data={sectionHeader2} className="center-content" />
              <TimelineItem>
                <div>coming soon...</div>
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;