import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import Roadmap from '../components/sections/Roadmap';
class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroSplit hasBgColor invertColor />
        <Roadmap topOuterDivider/>
      </React.Fragment>
    );
  }
}

export default Home;